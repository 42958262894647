<template>
  <v-card class="elevation-5">
    <v-card-text class="pl-9 pt-8 pb-1">
      <span class="headline">{{ $t('Settings for ') }} {{ name }}</span>
    </v-card-text>
    <v-container class="pl-9 pr-9">
      <v-form v-model="validForm">
        <v-row>
          <v-col>
            <v-text-field v-model="name" filled disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row style="min-width: 260px">
          <v-col>
            <p>
              {{
                $t(
                  'Select the TV channels which you want this organisation to have access to'
                )
              }}
            </p>
            <v-chip-group
              v-model="selectedChannelIndexes"
              column
              multiple
              class="channel-toggle"
            >
              <v-chip
                v-for="channel in AllChannels"
                :key="channel.id"
                outlined
                class="channel-chip"
                active-class="selected-channel"
                label
              >
                <img
                  v-if="channel.logo_url"
                  :src="channel.logo_url"
                  :alt="channel.name"
                />
                <span>{{ channel.name }}</span>
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              justify-end
              color="primary"
              large
              :loading="saving"
              :disabled="!hasChanges"
              @click="saveOrg"
            >
              {{ $t('SAVE') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<style lang="sass" scoped>
@import '@/scss/variables.scss'

.channel-toggle
  .channel-chip
    height: 80px
    width: 100px
    border-radius: 20px !important
    border-width: 2px
    margin: 4px
    opacity: 0.8
    img
      display: inline-block
      max-height: 32px
      max-width: 80px
      margin-bottom: 15px
    span
      position: absolute
      bottom: 5px
    &::v-deep .v-chip__content
      width: 100%
      display: flex
      justify-content: center
      flex-direction: column
    &.selected-channel
      opacity: 1
      border-color: map-get($digita, 'pink')
</style>

<script>
import _ from 'lodash'
import FormMixin from '@/mixins/FormMixin'
import { mapGetters } from 'vuex'
export default {
  params: {
    organisation: {
      type: String,
      required: true
    }
  },
  mixins: [FormMixin],
  data: () => ({
    validForm: false,
    init: true,
    saving: false
  }),
  computed: {
    ...mapGetters(['appReady']),
    hasChanges() {
      return this.$store.getters['organisations/editorHasChanges']
    },
    name: {
      get() {
        return this.$store.state.organisations.editor.name
      }
    },
    allOrgs: {
      get() {
        return _.flatMap(this.$store.state.organisations.organisations)
      }
    },
    organisationChannels() {
      let me = this
      me.$debug('we do all the computed elements')
      let selectedOrg = me.allOrgs.find(
        x => x.id == me.$route.params.organisationId
      )
      return _.orderBy(_.flatMap(selectedOrg.channels), 'name')
    },
    AllChannels() {
      let me = this
      let allChannels = me.$store.getters['channels/channels']
      return allChannels
    },

    selectedChannelIndexes: {
      get() {
        let me = this
        let indexes = me.organisationChannels.map(id => {
          return _.findIndex(me.AllChannels, ['id', id])
        })
        me.$debug('indexs', indexes)
        return indexes
      },
      set(indexes) {
        let me = this
        me.$debug('index', typeof indexes, indexes)
        let selectedChannels = []
        _.each(indexes, index => {
          //_.get(me.AllChannels, `[${index}].id`) - > valitun indexin channelin ID
          selectedChannels.push(_.get(me.AllChannels, `[${index}].id`))
        })
        me.$debug('selectedChannels', selectedChannels)
        me.$store.commit('organisations/set', [
          'editor.channels',
          selectedChannels
        ])
      }
    }
  },

  watch: {
    async appReady(status) {
      let me = this
      if (status) {
        await this.initEditor()
        me.$debug('inited editor with status: ', status)
      }
    }
  },
  async created() {
    let me = this
    me.$debug('created', me.$route.params.organisationId)
    await me.initEditor()
  },

  methods: {
    async initEditor() {
      let me = this
      if (!me.appReady) {
        return false
      }
      await me.$store.dispatch(
        'organisations/loadOrgToEditor',
        me.$route.params.organisationId
      )
      me.init = false
    },

    async saveOrg() {
      let me = this
      try {
        me.saving = true
        me.$debug('saveOrg', me.form)
        await me.$store.dispatch('organisations/updateOrgInEditor')
        me.saving = false
        me.$toast({
          type: 'success',
          message: 'Changes saved'
        })
        await me.initEditor()
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
        }
      }
    }
  }
}
</script>
